<!-- eslint-disable -->
<template>
<!-- ***** START Save Settings Dialog ***** -->
  <base-material-card
    color="blue"
    icon="mdi-water"
    :title="title"
    class="px-5 py-3 mt-10"
  >
    <v-form class="mt-5">
<!-- ***** Data Input Fields ***** -->
    <template>
      <v-text-field
        label="Report Name"
        v-model="sgname"
        class="pt-0 mb-3"
     />
    </template>
    </v-form>

<!-- ***** Action Buttons ***** -->
    <v-card-actions class="pl-0" >
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        min-width="50"
        text
        @click="oncancel()"
        >
        Cancel
      </v-btn>
      <v-btn
        color="success"
        min-width="50"
        text 
        @click="onsave()"
        >
        Save
      </v-btn>
      <v-btn
        color="red"
        min-width="25"
        text 
        @click="debuginstance()"
        >
        Debug
      </v-btn>
    </v-card-actions>
  </base-material-card>   
<!-- ***** END Save Settings Dialog ***** -->
</template>

<script>
/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import moment from 'moment'
import SettingsGroupService from '../../services/SettingsGroupService'

export default {
  name: 'SaveSettingsForm',
  components: { },
  mixins: [],
  props: {
    title: {
      type: String,
      default: 'Save Settings'},
    sgTypeId: {
      type: Number,
      default: 0
    },
    sgName: {
      type: String,
      default: "Default sgName"
    },
  },

//************************
// DATA
//************************
  data () { return {
    // showdialog: false,
    sgname: this.sgName,
  }},

//************************
// WATCH
//************************
watch: {
},

//************************
// METHODS
//************************
  methods: {
    debuginstance (val) {
    debugger
  },

//************ Save the entered readings
  onsave () {
    const newsgName = this.sgname
    // parent handles the saving and dialog closing
    this.$emit('saveentry', newsgName)
  },
 //***** Cancel/close
  oncancel() {
    // parent handles the dialog closing
    this.$emit('cancelentry')
  },

//************************
// NON-METHODS
//************************
  created () { }
}}

</script>