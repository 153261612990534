/* eslint-disable */

//***** SettingsGroup models for initializing the base reports that can be saved as Saved Reports

class SGComboTable {
//***** This chart displays multiple datasources across a common date range,in tabular format, as individual entries without aggregation.
  sgTypeId = 100;
  reportName;
  showFilters;
  organization;    // This is a Uid
  site;            // This is a Uid
  datasources;     // This is a [Uid]
  dateRangeMode = 0;   // 0 = Preset ; 1 = Custom
  customDateRange = null; // {StartDate, EndDate}
  presetDateRange = 0; // See below 
  rowsPerPage = 15;
  
constructor(reportname, showfilters, orguid, siteuid, datasources, datarangemode, customdatarange, presetdaterange, rowsperpage) {
  this.reportName = reportname;
  this.showFilters = showfilters;
  this.organization = orguid;         // This is a Uid
  this.site = siteuid;                // This is a Uid
  this.datasources = datasources;     // This is a [Uid]
  this.dateRangeMode = datarangemode;     // 0 = Preset ; 1 = Custom
  this.customDateRange = customdatarange; // [StartDate, EndDate]
  this.presetDateRange = presetdaterange; // integer ID - See below 
  /* Standard preset date ranges
    {name: 'This Week', id: '0'},
    {name: 'Last Week', id: '1'},
    {name: 'Today', id: '2'},
    {name: 'Yesterday', id: '3'},
    {name: 'This Month', id: '4'},
    {name: 'Last Month', id: '5'},
    {name: 'This Quarter', id: '6'},
    {name: 'Last Quarter', id: '7'},
    {name: 'This Year', id: '8'},
    {name: 'Last Year', id: '9'},
  */
 this.rowsPerPage = rowsperpage;      // integer
  }
}

class SGComboChart1 {
//***** This chart displays multiple datasources across a common date range aggregating results according to a common group-by period
  sgTypeId = 101;
  reportName;
  showFilters = false;
  organization;    // This is a Uid
  site;            // This is a Uid
  ds_bar1;     // DataSource for Bar Chart Series (Uid)
  ds_line1;        // DataSource for Line1 chart Series (Uid)
  ds_line2;        // DataSource for Lin2 chart Series (Uid)
  dateRangeMode;   // 0 = Preset ; 1 = Custom
  customDateRange; // {StartDate, EndDate}
  presetDateRange; // See below 
  presetGroupBy; // See below 
  groupBy;   // Datetime interval
  constructor(reportname, showfilters, orguid, siteuid, ds_bar1, ds_line1, ds_line2, datarangemode, customdatarange, presetdaterange, groupby) {
    this.reportName = reportname;
    this.showFilters = showfilters;
    this.organization = orguid;     // This is a Uid
    this.site = siteuid;            // This is a Uid
    this.ds_bar1 = ds_bar1;    // This is a Uid
    this.ds_line1 = ds_line1;          // This is a Uid
    this.ds_line2 = ds_line2;          // This is a Uid
    this.dateRangeMode = datarangemode;     // 0 = Preset ; 1 = Custom
    this.customDateRange = customdatarange; // [StartDate, EndDate]
    this.presetDateRange = presetdaterange; // object - See below 
    this.presetGroupBy = groupby;   // Datetime interval relative to now()
    this.groupBy = groupby;   // Datetime interval relative to now()
  }
}

class SGMeterCheckTable {
  //***** This chart displays 2 datasources, one of which is a meter reading and the other of which is a flow reading from the same meter (e.g. entries made from a contact closure measuring the flow).  The two datasources must be in the same units.   
  constructor(reportname, showfilters, orguid, siteuid, ds_meter, ds_usage, datarangemode, customdatarange, presetdaterange, rowsperpage) {
    this.sgTypeId = 104;
    this.reportName = reportname;
    this.showFilters = showfilters;
    this.organization = orguid;         // This is a Uid
    this.site = siteuid;                // This is a Uid
    this.ds_meter = ds_meter;           // This is a Uid
    this.ds_usage = ds_usage;           // This is a Uid
    this.dateRangeMode = datarangemode;     // 0 = Preset ; 1 = Custom
    this.customDateRange = customdatarange; // [StartDate, EndDate]
    this.presetDateRange = presetdaterange; // integer ID - See below 
   this.rowsPerPage = rowsperpage;      // integer
    }
  }

  class SGWellChart1 {
    //***** This chart displays multiple datasources across a common date range aggregating results according to a common group-by period
      sgTypeId = 105;
      reportName;
      showFilters = true;
      showSummary = true;
      showConfig = true
      organization;    // This is a Uid
      site;            // This is a Uid
      dateRangeMode;   // 0 = Preset ; 1 = Custom
      customDateRange; // {StartDate, EndDate}
      presetDateRange; // See below 
      presetGroupBy; // See below 
      groupBy;   // Datetime interval
      constructor(reportname, showfilters, showsummary, showconfig, orguid, siteuid, datarangemode, customdatarange, presetdaterange, groupby) {
        this.reportName = reportname;
        this.showFilters = showfilters;
        this.showSummary = showsummary;
        this.showConfig = showconfig;
        this.organization = orguid;     // This is a Uid
        this.site = siteuid;            // This is a Uid
        this.dateRangeMode = datarangemode;     // 0 = Preset ; 1 = Custom
        this.customDateRange = customdatarange; // [StartDate, EndDate]
        this.presetDateRange = presetdaterange; // object - See below 
        this.presetGroupBy = groupby;   // Datetime interval relative to now()
        this.groupBy = groupby;   // Datetime interval relative to now()
      }
    }
    
  
module.exports = { 
  SGComboTable : SGComboTable, 
  SGComboChart1 : SGComboChart1, 
  SGMeterCheckTable : SGMeterCheckTable,
  SGWellChart1 : SGWellChart1
 }