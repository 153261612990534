/* eslint-disable */
import axios from 'axios';

const url = 'api/settingsgroup/';

class SettingsGroupService {

// ******* Return all SettingsGroups for a specified user
static getAllSettingsGroups(useruid) {
  return new Promise( async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url + 'sggetall', {
        params: {
          sguseruid:useruid}
        });
        const data1 = res.data
        // console.log('data1 (settingsgroup)', data1)
        resolve(
          data1.data
        )
      } catch(err) {
        reject(err);
      }
    });
  }

// ******* Return a specified SettingsGroup by id
static getSettingsGroupById(sgid) {
  return new Promise( async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url + 'sggetbyid', {
        params: {
          sgid:sgid 
        }
        });
        const data1 = res.data
        // console.log('data1 (settingsgroup)', data1)
        resolve(
          data1.data
        )
      } catch(err) {
        reject(err);
      }
    });
  }
// ******* Create a new SettingsGroup record for a specific user and group of settings
  static createSettingsGroup(sgtypeid, sgname, useruid, sgsettings) {
    return new Promise( async (resolve, reject) => {
      // debugger
      try {
        const res = await axios.get(url + 'sgcreate', {
          params: {
            sgtypeid:sgtypeid,
            sgname:sgname,
            sguseruid:useruid,
            sgsettings: sgsettings
          }
          });
          const data1 = res.data
          // console.log('data1 (settingsgroup)', data1)
          resolve(
            data1.data
          )
        } catch(err) {
          reject(err);
        }
      });
  }

// ******* Update an existing SettingsGroup record for a specific group 
// *******  of settings *** NOTE: it is intentional that only sgname and
// *******  sgsettings can
static updateSettingsGroup(sgid, sgname, sgsettings) {
    return new Promise( async (resolve, reject) => {
      // debugger
      try {
        const res = await axios.get(url + 'sgupdate', {
          params: {
            sgid:sgid,
            sgname:sgname,
            sgsettings: sgsettings
          }
          });
          const data1 = res.data
          // console.log('data1 (settingsgroup)', data1)
          resolve(
            data1.data
          )
        } catch(err) {
          reject(err);
        }
      });
    }

  static deleteSettingsGroup(sgid) {
    return new Promise( async (resolve, reject) => {
      // debugger
      try {
        const res = await axios.get(url + 'sgdelete', {
          params: {
            sgid:sgid
          }
          });
          const data1 = res.data
          // console.log('data1 (settingsgroup)', data1)
          resolve(
            data1.data
          )
        } catch(err) {
          reject(err);
        }
      });
    }
}

  export default SettingsGroupService;