<!-- eslint-disable -->

<template>
  <v-container fluid>
    <v-toolbar-title>{{combochart1title}}</v-toolbar-title>
  <v-row no-gutters>
    <!-- ***** Update Chart ***** -->
    <v-btn
      color="green"
      dark
      small 
      class="mt-2 mr-4"
      @click='UpdateChart'
      >
      Update Chart
    </v-btn>
    <!-- ***** Save Settings ***** -->
    <v-dialog v-model="dialog2" max-width="320px" persistent>
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="light-blue"
        dark
        small 
        class="mt-2 mr-4"
        v-bind="attrs"
        v-on="on"
        @click="savesettingsformkey += 1"
        >Save Settings  </v-btn>
      </template>
      <save-settings-form
        title="Save Report Settings"
        v-on:cancelentry="onCancel2"
        v-on:saveentry="onSaveSettings"
        :key="savesettingsformkey"
      >
      </save-settings-form>
    </v-dialog>
    <!-- ***** Show Filters ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='toggleFilters'
      >
      {{ filterbtntext }}
    </v-btn>
    <!-- ***** Clear Datasources ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='clearDataSources'
      >
      Clear Datasources
    </v-btn>
    <!-- ***** Delete Saved Report ***** -->
    <v-btn v-if="id > 0"
      color="red lighten-1"
      small
      dark
      class="mt-2 mr-4"
      @click='onDeleteSavedReport'
      >
      Delete This Report
    </v-btn>
    <!-- ***** Debug Instance ***** -->
    <v-btn
      color="red"
      text 
      @click='debuginstance++'
      >
      Debug Instance
    </v-btn>
  </v-row>
    <!-- *********************************** -->
    <!-- ***** Filter / Settings Array ***** -->
    <!-- *********************************** -->
    <template>
      <div :key=showf v-if="showfilters">
      <v-row no-gutters>
        <v-col cols="3">
          <v-select
            v-on:change="populateSites"
            v-model="sel_org"
            :items="cb_orgs"
            item-text="name"
            item-value="uid"
            label="Organization"
            outlined
            dense
            class="mt-4"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="sel_site"
            :items="cb_sites"
            item-text="name"
            item-value="uid"
            label="Site"
            outlined
            dense
            class="mt-4"
          ></v-select>
        </v-col>
      </v-row>
    <!-- ***** Datasources ***** -->
      <v-row no-gutters>
        <v-col cols="3">
          <v-select
            v-model="sel_dsbar1_index"
            :items="cb_dsources"
            label="Bar Data Source"
            item-text="name"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-select
            v-model="sel_dsbar1style"
            :items="cb_dsstyles"
            label="Bar Style"
            item-text="name"
            item-value="id"
            outlined
            background-color="green lighten-5"
            dense
            return-object
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="sel_dsline1_index"
            :items="cb_dsources"
            label="Line 1 Data Source"
            item-text="name"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-select
            v-model="sel_dsline1style"
            :items="cb_dsstyles"
            label="Line 1 Style"
            item-text="name"
            item-value="id"
            outlined
            background-color="green lighten-5"
            dense
            return-object
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="sel_dsline2_index"
            :items="cb_dsources"
            label="Line 2 Data Source"
            item-text="name"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-select
            v-model="sel_dsline2style"
            :items="cb_dsstyles"
            label="Line 2 Style"
            item-text="name"
            item-value="id"
            outlined
            background-color="green lighten-5"
            dense
            return-object
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <!-- Switch between Preset and Custom Date Range modes -->
        <v-col cols="3">
          <v-btn
            class="mr-3"
            elevation="1"
            fab
            small
            color="success"
            @click="sw_dmode = ! sw_dmode"
          >
            <v-icon v-if="sw_dmode">
              mdi-calendar-multiple
            </v-icon>
            <v-icon v-else>
              mdi-dns
            </v-icon>
          </v-btn> -->
          {{sw_dmodelabel}}
        </v-col>
        <v-col 
          v-if="!sw_dmode"
          cols="2">
          <v-select
            v-model="sel_drange"
            :items="cb_drange"
            label="Date Range"
            item-text="name"
            item-value="id"
            outlined
            color="green"
            red--text
            dense
            return-object
          ></v-select>
        </v-col>

      <!-- Start Date -->
        <v-col cols="2"
          v-if="sw_dmode"
        >
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datastartdate"
            label="Start Date"
            readonly
            outlined
            dense
            color="green"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datastartdate"
          no-title
          scrollable
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu>
      </v-col>

      <!-- End Date -->
      <v-col cols="2"
        v-if="sw_dmode"         
        >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dataenddate"
            label="End Date"
            readonly
            outlined
            dense
            color="green"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dataenddate"
          no-title
          scrollable
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu>
      </v-col>

      <!-- Group By -->
        <v-col cols="2">
          <v-select
            v-model="sel_groupby"
            :items="cb_groupby"
            label="Group By"
            item-text="name"
            item-value="id"
            outlined
            color="green"
            dense
            return-object
          ></v-select>
        </v-col>
      </v-row>
      </div>
      <div v-else>
        <v-divider> </v-divider>
      </div>
    </template>
    <template
      v-if="dontshow_chart"
    >     
      <p class="text-center text-h4 grey--text">(No Data to Chart)
      </p>
    </template>
    
    <template
      v-if="!dontshow_chart"
    >     
    <v-row>
      <v-col cols="12">
<!-- *********** DATA CHART HERE ************ -->
        <vertical-combo-chart3
          :ChartLabels="ChartLabels"
          :barChart1Label = "barChart1Label"
          :barChart1AxisId = "barChart1AxisId"
          :barChart1Datax="barChart1Datax"
          :barChart1Datay="barChart1Datay"
          :barChart1Hidden="barChart1Hidden"
          :lineChart1Label="lineChart1Label"
          :lineChart1AxisId = "lineChart1AxisId"
          :lineChart1Datax="lineChart1Datax"
          :lineChart1Datay="lineChart1Datay"
          :lineChart1Hidden="lineChart1Hidden"
          :lineChart2Label="lineChart2Label"
          :lineChart2AxisId = "lineChart2AxisId"
          :lineChart2Datax="lineChart2Datax"
          :lineChart2Datay="lineChart2Datay"
          :lineChart2Hidden="lineChart2Hidden"
          :yAxisLeft="yAxisLeft"
          :yAxisRight="yAxisRight"
          :yAxisLeftLabel="yAxisLeftLabel"
          :yAxisRightLabel="yAxisRightLabel"
          :key="keychart"
        />
      </v-col>

    </v-row>
    <v-row>
        <v-divider v-if="showdatatable"> </v-divider>
    </v-row>
<!-- **************************************** -->
<!-- *********** DATA TABLE HERE ************ -->
<!-- **************************************** -->
    <v-row>
      <v-col cols="12">
      <v-data-table
        v-if="showdatatable"
        dense
        :headers="headers"
        :hide-default-footer="true"
        :items="datatable_data"
        :sort-desc="true"
      >
      </v-data-table>
      </v-col>
    </v-row>
    <v-row>
        <v-divider v-if="showdatatable"> </v-divider>
    </v-row>
    </template>
  </v-container>
</template>

<script>

/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import DataSourceService from '../../services/DataSourceService'
import CrmService from '../../services/CrmService'
import helperMixin from '@/mixins/helperMixin'
import Moment from 'moment'
import DatalogService from '../../services/DatalogService'
import Datalog2Service from '../../services/Datalog2Service'
import ConfigService from '../../services/ConfigService'
import VerticalComboChart3 from '../../components/charts/VerticalComboChart3'
import SettingsGroupService from '../../services/SettingsGroupService'
import SaveSettingsForm from '../../components/forms/SaveSettingsForm.vue'
import { EventBus } from '../../event-bus.js'
import { mapState, mapMutations } from 'vuex'
// var SG = require('../../../server/models/m_settingsgroup')
var GS = require( '../../models/m_groupsettings')
const momentrange = require('moment-range')
const moment = momentrange.extendMoment(Moment)

export default {
  name: 'combochart1',
  components: {VerticalComboChart3,SaveSettingsForm},
  mixins: [helperMixin],
  props: { 
    id: {
    type: String,
    default: '0'
  }},

//************************
// DATA
//************************
  data () {  return {
    keychart: 0,
    dialog2: false,
    showf: 0,
    savesettingsformkey: 0,
    debuginstance: 0,
    menu1: false,
    menu2: false,
    combochart1title: 'Combo Chart 1 (Base)',
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    // Chart Series
    hours24: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    ChartLabels: [],

      showdatatable: false,
      init_headers_dssummary: [
      { text: 'Start Date',sortable: true, value: 'startDate', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'End Date',sortable: true, value: 'endDate', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Datasource',sortable: true, value: 'text', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Period Entries',sortable: true, value: 'entryCount', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Period Sum',sortable: true, value: 'periodSum', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Daily Avg',sortable: true, value: 'dailyAvg', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Daily Min',sortable: true, value: 'dailyMin', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Daily Max)',sortable: true, value: 'dailyMax', class: "green lighten-5 black--text subtitle-2", align: "start"},
    ],
    headers: [],
    datatable_data: [],

    barChart1Datax: [],
    barChart1Datay: [],
    lineChart1Datax: [],
    lineChart1Datay: [],
    lineChart2Datax: [],
    lineChart2Datay: [],
    barChart1Label: 'House Water Usage',
    lineChart1Label: 'House Tank Fill',
    lineChart2Label: 'Propane Meter',
    barChart1Hidden: false,
    lineChart1Hidden: false,
    lineChart2Hidden: false,
    barChart1AxisId: "", // "idleft" or "idright"
    lineChart1AxisId: "", // "idleft" or "idright"
    lineChart2AxisId: "", // "idleft" or "idright"
    yAxisLeft: true,  // show left axis?
    yAxisRight: false, // show right axis?
    yAxisLeftLabel: '',
    yAxisRightLabel: '',
    
    //********** Filters / Settings Data */
    sgTypeId: 101,  // Settings Group type of this component 
    sgTypeName: "Combo Chart 1",
    reportname: 'Combo Chart 1',
    showfilters: 'true',
    dontshow_chart: 'true',
    datastartdate: '',
    dataenddate: '',
    sel_org: {},
    cb_orgs: [],
    sel_site: {},
    cb_sites: [],
    // filterbtntext: 'Show Filters',
    loadingsettings: false,
    // DataSources
    cb_dsources: [],
    sel_dsbar1: null,
    sel_dsbar1_index: null, // not saved
    sel_dsline1: null,
    sel_dsline1_index: null, // not saved
    sel_dsline2: null,
    sel_dsline2_index: null, // not saved
    cb_dsstyles: [
      {name: 'SUMS', id: '0'},
      {name: 'CUMULATIVE', id: '1'}
      // {name: 'BURNDOWN', id: '2'}
    ],
    sel_dsbar1style:{name: 'SUMS', id: '0'},
    sel_dsline1style: {name: 'SUMS', id: '0'},
    sel_dsline2style: {name: 'SUMS', id: '0'},
    // Time/Date
    sw_dmode: false,  // false = Preset , true = Custom
    sw_dmodelabel: 'Date Range Mode: Preset',
    sel_drange: {name: 'Last Week', id: '0'},
    cb_drange: [
      {name: 'This Week', id: '0'},
      {name: 'Last Week', id: '1'},
      {name: 'Today', id: '2'},
      {name: 'Yesterday', id: '3'},
      {name: 'This Month', id: '4'},
      {name: 'Last Month', id: '5'},
      {name: 'This Quarter', id: '6'},
      {name: 'Last Quarter', id: '7'},
      {name: 'This Year', id: '8'},
      {name: 'Last Year', id: '9'},
    ],
    sel_groupby: {name: 'Day', id: '1', disabled: false},
    cb_groupby: [
      {name: 'Hour', id: '0', disabled: true},
      {name: 'Day', id: '1', disabled: false},
      {name: 'Week', id: '2', disabled: true},
      {name: 'Month', id: '3', disabled: true},
      {name: 'Year', id: '4', disabled: true}],
  }
},
//************************
// WATCH
//************************
watch: {
  debuginstance (val) {
    this.testx()
debugger
  },
  datastartdate (val) {
    if (val > this.dataenddate) this.dataenddate = val
    this.setGroupBy()
  },
  dataenddate (val) {
    if (val < this.datastartdate) this.datastartdate = val
    this.setGroupBy()
  },
  sel_drange (val) {
    this.setGroupBy()
    // debugger
  },
  sw_dmode (val) {
    this.sw_dmodelabel = !val ? 'Date Range Mode: Preset' : 'Date Range Mode: Custom'
    if (val && this.loadingsettings) { // Set defaults for Custom
      let today = today = moment().format('YYYY-MM-DD')
      this.datastartdate = today
      this.dataenddate = today
      this.setGroupBy()
    }
  },
  sel_dsbar1_index (val) {
    let sel_dsbar1 = this.cb_dsources[val]
    if (sel_dsbar1 == null) return
    this.sel_dsbar1 = sel_dsbar1
// debugger
  },
  sel_dsline1_index (val) {
    let sel_dsline1 = this.cb_dsources[val]
    if (sel_dsline1 == null) return
    this.sel_dsline1 = sel_dsline1
// debugger
  },
  sel_dsline2_index (val) {
    let sel_dsline2  = this.cb_dsources[val]
    if (sel_dsline2 == null) return
    this.sel_dsline2 = sel_dsline2
// debugger
  },

    
  // datastartdate (val, OldValue) {
  //   if (!this.ValidateStartEndDates(val, this.dataenddate)) { alert('Start Date must be earlier than (or same as) End Date!') } else {
  //     this.datastartdate = val
  //   }
  //   this.setGroupBy()  },

  // dataenddate (val) {
  //   if (!this.ValidateStartEndDates(val, this.dataenddate)) { alert('End Date must be later than (or same as) End Date!') } else {
  //     this.dataenddate = val
  //   }
  //   this.setGroupBy()  },

},
//************************
// COMPUTED
//************************
computed: {
  filterbtntext () {
    const fbt = this.showfilters ? 'Hide Filters' : 'Show Filters'
    return fbt
  }
},
//************************
// METHODS
//************************
methods: {
async testx() {
// const test = await Datalog2Service.getDL2RecordById(9)
// debugger
  },

  toggleFilters() {
    this.showfilters = !this.showfilters
    this.showf += 1
  },
  clearChartData () {
    this.barChart1Datay = []
    this.barChart1Datax = []
    this.lineChart1Datax = []
    this.lineChart1Datay = []
    this.lineChart2Datax = []
    this.lineChart2Datay = []
    this.dontshow_chart = true
  },
  clearDataSources() {
    this.sel_dsbar1 = null
    this.sel_dsline1 = null
    this.sel_dsline2 = null
    this.sel_dsbar1_index = null
    this.sel_dsline1_index = null
    this.sel_dsline2_index = null
    this.barChart1Label = ''
    this.lineChart1Label = ''
    this.lineChart2Label = ''
    this.clearChartData()
    this.populatedatasources
    this.keychart++
    this.dontshow_chart = true
  },
  async onDeleteSavedReport() {
    try {
    SettingsGroupService.deleteSettingsGroup(this.id)
      // Update the Saved Reports Nav Drawer
      EventBus.$emit('updateCVDrawer', true)
      alert ("This Saved Report has been deleted!")
      this.$router.push({path: '/basereports/combochart1/0'})
    } catch (err) {
      console.log('ComboChart > deleteSettingsGroup ERROR = ', err.message);
    }
  },

// populate organization selector with those orgs that are accessible to the
//  current user 
  async populateOrganizations() {
    try {
      this.cb_orgs = [];
      const loggedin = this.$store.state.loggedin
      const current_customer = this.$store.state.current_customer
      const orgs = await CrmService.customerSelectAll(loggedin, current_customer);
      this.cb_orgs = orgs.map(o => ({ name: o.organizationName, uid: o.customerUid }));
      this.sel_org = this.cb_orgs[0].uid;
      // console.log('ComboChart1 > populateOrganizations: cb_orgs = ', this.cb_orgs); 
      this.populateSites();
    } 
    catch (err) {
      console.log(err.message);
    }
  },

  // Validate that org we want to (programmatically) select is in the select list
  //  Used when recalling saved settings (maybe the list of orgs is different now)
  selectOrg(organization) {
  try {  
    const orgs = this.cb_orgs 
    let orgselected = false
    for (let org of orgs) {
      if (organization == org.uid) {
        this.sel_org = org.uid
        orgselected = true
      }
    }
    if (!orgselected) { throw "Organization to select is not available !"}
    
    }
    catch (err) {
      console.log('ComboTable > selectOrg ERROR = ', err.message);
    }
  },

  async populateSites() {
    try {
      this.cb_sites = [];
      const sites = await CrmService.siteSelectByCustomerUid(this.sel_org);
      this.cb_sites = sites.map(s => ({ name: s.siteName, uid: s.siteUid }));
      this.sel_site = this.cb_sites[0].uid;
      // console.log('ComboChart1 > populateSites: cb_orgs = ', this.cb_sites); 
      this.populateDataSources();
    } 
    catch (err) {
      console.log(err.message);
    }
  },

// Set the selected site based on parameter (Uid)
  selectSite(siteuid) {
    try {
      // debugger
    // Search through the list of available sites to select from
      const sites = this.cb_sites
      let siteselected = false
      for (let site of sites) {
        if (siteuid == site.uid)
        { this.sel_site = site.uid 
          siteselected = true
        }
      }
      if (!siteselected) { throw "Site to select is not available !"}
    } catch (err) {
      console.log('ComboTable > selectSite ERROR = ', err.message);
    }
  },

// ***** Populate all datasources for the current site).  
// *****  arr_of_dsources_to_select specifies which ones to select, if any
  async populateDataSources() {
    try {
      const dsrc = await DataSourceService.dsGetBySite(this.sel_site)
      this.cb_dsources = []
      let item, index = 0
      // debugger
      for (item of dsrc) {
        if (item.suppressUI > 0) continue
        let tdsrc = {name: null, uid: null, id: null, units: null};
        tdsrc.name = item.dataSourceName + " (" + item.units + ")";
        tdsrc.uid = item.dataSourceUid;
        tdsrc.id = index;
        tdsrc.units = item.units;
        this.cb_dsources.push(tdsrc);
        index++;
      }
      const cid = this.id
      if (cid > 0) {
        // Selections will be made from the saved settings
        this.loadSettings(cid)
      } 
    //  debugger
    } catch (err) {
      console.log('ComboChart1 - populateDataSources: err= ', err.message)
    }
  }, 


  setGroupBy() {
    // Disable/enable items based on date range selector
    if (!this.sw_dmode) { // Presets data range mode
    // set selection based on this.sel_drange.id
      switch (Number.parseInt(this.sel_drange.id)) {
        case 0: { // This Week
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = false 
          this.cb_groupby[2].disabled = true 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[1]
          break;
        }
        case 1: { // Last Week
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = false 
          this.cb_groupby[2].disabled = true 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[1]
          break;
        }
        case 2: { // Today
          this.cb_groupby[0].disabled = false 
          this.cb_groupby[1].disabled = true 
          this.cb_groupby[2].disabled = true 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[0]
          break;
        }
        case 3: { // Yesterday
          this.cb_groupby[0].disabled = false 
          this.cb_groupby[1].disabled = true 
          this.cb_groupby[2].disabled = true 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[0]
          break;
        }
        case 4: { // Last Month
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = false 
          this.cb_groupby[2].disabled = false 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[1]
          break;
        }
        case 5: { // This Month
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = false 
          this.cb_groupby[2].disabled = false 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[1]
          break;
        }
        case 6: { // This Quarter
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = true 
          this.cb_groupby[2].disabled = false 
          this.cb_groupby[3].disabled = false 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[2]
          break;
      }
        case 7: { // Last Quarter
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = true 
          this.cb_groupby[2].disabled = false 
          this.cb_groupby[3].disabled = false 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[2]
          break;
      }
        case 8: { // This Year
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = true 
          this.cb_groupby[2].disabled = false 
          this.cb_groupby[3].disabled = false 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[2]
          break;
        }
        case 9: { // Last Year
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = true 
          this.cb_groupby[2].disabled = false 
          this.cb_groupby[3].disabled = false 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[2]
          break;
        }
      }
// debugger
    } else { // Custom data range mode
      if (this.datastartdate == this.dataenddate) { // one day, so hours only
          this.cb_groupby[0].disabled = false 
          this.cb_groupby[1].disabled = true 
          this.cb_groupby[2].disabled = true 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[0]
      } else  { // days, weeks
          this.cb_groupby[0].disabled = true 
          this.cb_groupby[1].disabled = false 
          this.cb_groupby[2].disabled = false 
          this.cb_groupby[3].disabled = true 
          this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[1]
      }
    }
  },

//************ Load the saved settings designated by the PK sgid
  async loadSettings(sgid) {
    try {
      this.loadingsettings == true
      const settingsgroup = await SettingsGroupService.getSettingsGroupById(sgid)
      const settings = JSON.parse(settingsgroup.sgSettings)
      // console.log(settings)
      if (settings.sgTypeId != this.sgTypeId) throw 'Loaded Settings Group TypeID Mismatch - this component requires sgTypeID = ' + this.sgTypeId
      // Set the settings for this component
      this.reportname = "(SAVED): \"" + settings.reportName + "\""
      this.sw_dmode = settings.dateRangeMode
      this.sel_drange = settings.presetDateRange
      // debugger
      this.sel_groupby = settings.groupBy
      this.setGroupBy()
      this.datastartdate = settings.customDateRange[0]
      this.dataenddate = settings.customDateRange[1]
      this.selectOrg(settings.organization)
      this.selectSite(settings.site)
      this.selectDataSources(settings.ds_bar1, settings.ds_line1, settings.ds_line2)
      // debugger
      this.UpdateChart()
      if (this.showfilters != settings.showFilters) this.toggleFilters()
    } catch (err) {
      console.log('ComboChart1 > loadSettings ERROR = ', err.message);
    } finally {
      this.loadingsettings == false
  } 
  },
  
//************ Handler for saving current settings (Called from SaveSettingsForm component)
  async onSaveSettings (newsgName) {
    try {
      this.dialog2 = false
      // <TBD> Validate that adequate settings are available to save 
      //       If not, then raise error.
      // meta
      const sgtypeid = this.sgTypeId
      const sgtypename = this.sgTypeName
      const reportname = newsgName
      const showfilters = this.showfilters
      // owner
      const userUid = this.$store.state.loggedin.userUid
      const sel_org = this.sel_org
      const sel_site = this.sel_site
      // datasources
    
      let dsbar1, dsline1, dsline2
      let atleastoneds = false
      if (this.sel_dsbar1) {
        dsbar1 = this.sel_dsbar1.uid
        atleastoneds = true }
      if (this.sel_dsline1) { 
        dsline1 = this.sel_dsline1.uid
        atleastoneds = true }
      if (this.sel_dsline2) {
        dsline2 = this.sel_dsline2.uid
        atleastoneds = true }
      if (!atleastoneds) {
        // All datasources are blank - can't have that
        alert('Select at least one datasource!')
        return
      } 
      // console.log (this.sel_dsbar1, this.sel_dsline1, this.sel_dsline2)
      // time/date
      const sw_dmode = this.sw_dmode
      const datastartdate = this.datastartdate
      const dataenddate = this.dataenddate
      const sel_drange = this.sel_drange
      const sel_groupby = this.sel_groupby
      //
      var settings = new GS.SGComboChart1(reportname,showfilters, sel_org ,sel_site, dsbar1, dsline1, dsline2, sw_dmode, [datastartdate,dataenddate], sel_drange, sel_groupby)
      // console.log("ComboTable > onSaveSettings: settings = ", settings)
      // debugger
      const newsg = await SettingsGroupService.createSettingsGroup(sgtypeid, sgtypename, userUid, settings)
      console.log("ComboTable > onSaveSettings: newsg = ", newsg)
      // Create Saved Reports Link
      EventBus.$emit('updateCVDrawer', true)

    } catch (err) {
      console.log('ComboTable > onSaveSettings ERROR = ', err.message);
    }
    },
// Cancel out the save settings dialog
  onCancel2 () {this.dialog2 = false},

// ***** Init datasources - the 3 selected datasources are used to prep for chart updating
  initDataSources() {
    const dsbar1 = this.sel_dsbar1 == null ? null : this.sel_dsbar1.uid 
    const dsline1 = this.sel_dsline1 == null ? null : this.sel_dsline1.uid 
    const dsline2 = this.sel_dsline2 == null ? null : this.sel_dsline2.uid 
    this.selectDataSources(dsbar1, dsline1, dsline2)
  },

// ***** Select datasources - must be in the select list
  selectDataSources (ds_bar1, ds_line1, ds_line2) {
      // Search through the list of available datasources to select from
      let leftaxisunits, rightaxisunits, leftaxislabel, rightaxislabel
          this.yAxisLeft = true, this.yAxisRight = false
      let sel_dsbar1, sel_dsline1, sel_dsline2
      let sel_dsbar1_index, sel_dsline1_index, sel_dsline2_index
      const datasources = this.cb_dsources
      // ******************************
      // ***************** Bar 1 Chart
      // ******************************
      if (ds_bar1 != null) {
      for (let index = 0; index < datasources.length; index++) {
        if (ds_bar1 == datasources[index].uid) { // this is the ds to select
          sel_dsbar1 = datasources[index]
          sel_dsbar1_index = index
          this.barChart1AxisId = "idleft"
          leftaxisunits = sel_dsbar1.units
          if (leftaxisunits == 'gal' || leftaxisunits == 'psi') {leftaxislabel = 'Water (Gallons)'} else {leftaxislabel = 'propane (Gallons)'}
          this.sel_dsbar1 = sel_dsbar1
          this.sel_dsbar1_index = sel_dsbar1_index
          this.barChart1Label = sel_dsbar1.name
          break }
          } }

      if (ds_line1 != null) {
      // ******************************
      // ***************** Line 1 Chart
      // ******************************
      for (let index = 0; index < datasources.length; index++) {
        if (ds_line1 == datasources[index].uid) { // this is the ds to select
          sel_dsline1 = datasources[index]
          sel_dsline1_index = index
          if (leftaxislabel != null) { // Left Axis is set up, so use it here if it matches
            if (leftaxisunits == sel_dsline1.units) { // left axis  Matches, use the left axis
              this.lineChart1AxisId = "idleft"} else { // not a Match, so switch to right axis
              this.lineChart1AxisId = "idright"
              rightaxisunits = sel_dsline1.units
              this.yAxisRight=true
              if (rightaxisunits == 'gal' || rightaxisunits == 'psi') {rightaxislabel = 'Water (Gallons)'} else  {rightaxislabel = 'propane (Gallons)'}
              }
          } else { // Left Axis is NOT set up, so set it up and use it
            this.lineChart1AxisId = "idleft"
            leftaxisunits = sel_dsline1.units
            if (leftaxisunits == 'gal' || leftaxisunits == 'psi') {leftaxislabel = 'Water (Gallons)'} else {leftaxislabel = 'propane (Gallons)'}
          }
          // Fall through to here in either the right or left axis case
            this.sel_dsline1 = sel_dsline1
            this.sel_dsline1_index = sel_dsline1_index
            this.lineChart1Label = sel_dsline1.name
            break }
          } }
      if (ds_line2 != null) {
      // ******************************
      // ***************** Line 2 Chart
      // ******************************
      for (let index = 0; index < datasources.length; index++) {
        if (ds_line2 == datasources[index].uid) { // this is the ds to select
          sel_dsline2 = datasources[index]
          sel_dsline2_index = index
          if (leftaxislabel != null) { // Left Axis is set up, so use it here if it matches
            if (leftaxisunits == sel_dsline2.units) { // left axis  Matches, use the left axis
              this.lineChart2AxisId = "idleft"} else { // not a Match, so switch to right axis
              this.lineChart2AxisId = "idright"
              rightaxisunits = sel_dsline2.units
              this.yAxisRight=true
              if (rightaxisunits == 'gal' || rightaxisunits == 'psi') {rightaxislabel = 'Water (Gallons)'} else  {rightaxislabel = 'propane (Gallons)'}
              }
          } else { // Left Axis is NOT set up, set it up and use it
            this.lineChart1AxisId = "idleft"
            leftaxisunits = ds_line2.units
            if (leftaxisunits == 'gal' || leftaxisunits == 'psi') {leftaxislabel = 'Water (Gallons)'} else {leftaxislabel = 'propane (Gallons)'}
          }
          // Fall through to here in either the right or left axis case
          this.sel_dsline2 = sel_dsline2
          this.sel_dsline2_index = sel_dsline2_index
          this.lineChart2Label = sel_dsline2.name
          break }
          } } 
          
      // Set props
      this.yAxisLeftLabel = leftaxislabel
      this.yAxisRightLabel = rightaxislabel
      // debugger
  },


//******* InitTableStructure
// this.sel_dsources MUST be initialized before calling this
InitTableStructure() {
  this.datatable_data = []  // Clear the datatable
  this.headers = this.init_headers_dssummary
},

//******* UpdateChart
  async UpdateChart () {
  this.initDataSources()
  const config = await ConfigService.settingsGetOverview()
  let today = config.todayOverride
  if (today=="") { today = moment().format('YYYY-MM-DD') }
// debugger
    // Set up Bar Chart
    // const today = new Date('2020-11-06') // initialize "today" to newest data date
    let sel_drange_id = this.sel_drange.id
    let startdate, enddate, daterange
    startdate = null
    //******* Which Date Range Mode? ********/
    if (!this.sw_dmode) {
      // Preset Mode - set startdate/enddate
      switch (Number.parseInt(sel_drange_id)) {
        case 0: { // This Week
          daterange = this.GetWeekRange2(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 1: { // Last Week
          daterange = this.GetWeekRange2(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        case 2: { // Today
          startdate = moment(today).format('YYYY-MM-DD')
          enddate = startdate + " 23:59:59"
          startdate +=  " 00:00:00"
          break;
        }
        case 3: { // Yesterday
          startdate = moment(today).subtract(1,'days').format('YYYY-MM-DD')
          enddate = startdate
          break;
        }
        case 4: { // This Month
          daterange = this.GetMonthRange(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 5: { // Last Month
          daterange = this.GetMonthRange(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        case 6: { // This Quarter
          daterange = this.GetQuarterRange(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 7: { // Last Quarter
          daterange = this.GetQuarterRange(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        case 8: { // This Year
          daterange = this.GetYearRange(today)
          startdate = daterange[0]
          enddate = daterange[1]
          break;
        }
        case 9: { // Last Year
          daterange = this.GetYearRange(today)
          startdate = daterange[2]
          enddate = daterange[3]
          break;
        }
        // this.startdate = startdate
        // this.enddate = enddate
      }
    } else {
      // Custom Mode - user selects start and end dates
      startdate = this.datastartdate
      enddate = this.dataenddate

    }

//*******
//******* Get data and prep for chart input via props ********/
//*******
    try {
      let sel_groupby_id = this.sel_groupby.id
      let vbardata = null, vbarplotdata = null
      let vline1data = null, vline1plotdata = null
      let vline2data = null, vline2plotdata = null
      this.clearChartData()
      let commonDataX=[]
      let xLabelMatch = ''
      let barChart1Datax=[], lineChart1Datax=[], lineChart2Datax=[]
      let barChart1Datay=[], lineChart1Datay=[], lineChart2Datay=[]
      let sel_dsbar1 = this.sel_dsbar1, sel_dsline1 = this.sel_dsline1, 
          sel_dsline2 = this.sel_dsline2, sel_dsbar1style = this.sel_dsbar1style,sel_dsline1style = this.sel_dsline1style,sel_dsline2style = this.sel_dsline2style
      let barChart1Hidden = false, lineChart1Hidden = false, 
          lineChart2Hidden = false
      // Make sure at least one datasource is selected for chart  
      let length = 0
      if (sel_dsbar1 == null && sel_dsline1 == null && sel_dsline2 == null) {
        //************ No datasets selected for chart */
        alert('No data selected for display!')
      } else {

      switch (Number.parseInt(sel_groupby_id)) { // switch to sum by period
      // Hourly (always one day's worth)
        case 0: {
          if (sel_dsbar1 != null) {
            vbardata = await DatalogService.getHourlySums(startdate, sel_dsbar1.uid) }
            // console.log(vbardata)
          if (sel_dsline1 != null) {
            vline1data = await DatalogService.getHourlySums(startdate, sel_dsline1.uid) }
          if (sel_dsline2 != null) {
            vline2data = await DatalogService.getHourlySums(startdate, sel_dsline2.uid) }
          // Create common x axis of days for the daterange
          commonDataX = this.hours24 // 00-23
// debugger
          break; } // end -  Hourly (always one day's worth)

      // Daily (Variable time period)
        case 1: { 

          //********* Bar Chart
          if (sel_dsbar1 != null) {
            if (sel_dsbar1style.id == 0) {
              // Sums Charting
              vbardata = await DatalogService.getDailySums0(startdate, enddate, sel_dsbar1.uid, sel_dsbar1.units)
              vbarplotdata = vbardata.map(o => ({plotdata: o.groupTotals, saveMonthDay: o.saveMonthDay})) }
            else if (sel_dsbar1style.id == 1 ) {
              // Cumulative Charting
              vbardata = await DatalogService.getDailyCumulativeValues(startdate, enddate, sel_dsbar1.uid, sel_dsbar1.units, 350)
              vbarplotdata = vbardata.map(o => ({plotdata: o.cumulative_sum, saveMonthDay: o.saveMonthDay})) }
            else {alert("Bar Chart Datasource): Burndown not yet supported!")} }
          //********* Line 1 Chart
          if (sel_dsline1 != null) {
            if (sel_dsline1style.id == 0) {
              // Sums Charting
              vline1data = await DatalogService.getDailySums0(startdate, enddate, sel_dsline1.uid, sel_dsline1.units) 
              vline1plotdata = vline1data.map(o => ({plotdata: o.groupTotals, saveMonthDay: o.saveMonthDay})) } 
            else if (sel_dsline1style.id == 1 ) {
              // Cumulative Charting
              vline1data = await DatalogService.getDailyCumulativeValues(startdate, enddate, sel_dsline1.uid, sel_dsline1.units,350) 
              vline1plotdata = vline1data.map(o => ({plotdata: o.cumulative_sum, saveMonthDay: o.saveMonthDay})) }
            else {alert("(Line 1 Chart Datasource): Burndown not yet supported!")} }
          //*********** Line 2 Chart
          if (sel_dsline2 != null) {
            if (sel_dsline2style.id == 0) {
              // Sums Charting
              vline2data = await DatalogService.getDailySums0(startdate, enddate, sel_dsline2.uid, sel_dsline2.units) 
              vline2plotdata = vline2data.map(o => ({plotdata: o.groupTotals, saveMonthDay: o.saveMonthDay})) } 
            else if (sel_dsline2style.id == 1 ) {
              // Cumulative Charting
              vline2data = await DatalogService.getDailyCumulativeValues(startdate, enddate, sel_dsline2.uid, sel_dsline2.units,350)
              vline2plotdata = vline2data.map(o => ({plotdata: o.cumulative_sum, saveMonthDay: o.saveMonthDay})
              ) }            
            else {alert("(Line 1 Chart Datasource): Burndown not yet supported!")} }

          // Create common x axis of days for the daterange
          var mdaterange = moment.range(moment(startdate), moment(enddate))
          var dayrangearray = Array.from(mdaterange.by('day'))
          commonDataX = dayrangearray.map(dr => {return dr.utc().format('M-DD')
          })
// debugger
            break; }  // end - Daily (Variable time period)

      // Weekly (Variable time period)
        case 2: { 
          // Extend to Full Weeks
          const extendtofullweeks = this.ExtendtoFullWeeks(startdate, enddate)
          startdate = extendtofullweeks[0]
          enddate = extendtofullweeks[1]
          if (sel_dsbar1 != null) {
            vbardata = await DatalogService.getWeeklySums(startdate, enddate, sel_dsbar1.uid)}
          if (sel_dsline1 != null) {
            vline1data = await DatalogService.getWeeklySums(startdate, enddate, sel_dsline1.uid) }
          if (sel_dsline2 != null) {
            vline2data = await DatalogService.getWeeklySums(startdate, enddate, sel_dsline2.uid) }
          // Create common x axis of weeknums for the daterange
          var mdaterange = moment.range(moment(startdate), moment(enddate))
          var weekrangearray = Array.from(mdaterange.by('week'))
          commonDataX = weekrangearray.map(dr => {return dr.utc().format('ww')
          })
// debugger        
          break; }  // end - Weekly (Variable time period)
      // Monthly (Variable time period) {        
        case 3: { 
          if (sel_dsbar1 != null) {
            vbardata = await DatalogService.getMonthlySums(startdate, enddate, sel_dsbar1.uid)}
          if (sel_dsline1 != null) {
            vline1data = await DatalogService.getMonthlySums(startdate, enddate, sel_dsline1.uid) }
          if (sel_dsline2 != null) {
            vline2data = await DatalogService.getMonthlySums(startdate, enddate, sel_dsline2.uid) }
          // Create common x axis of months for the daterange
          var mdaterange = moment.range(moment(startdate), moment(enddate))
          var monthrangearray = Array.from(mdaterange.by('month'))
          commonDataX = monthrangearray.map(dr => {return dr.utc().format('M')
          })
// debugger        
          break; }  // end - Monthly (Variable time period)
      // Yearly (Variable time period) {        
        case 4: { 
          // NOT IMPLEMENTED YET!!!
// debugger        
          break; }  // end - Yearly (Variable time period)
        
        } // end switch
// debugger

  // Continue to finish up prepping data for charting
  if (vbardata == null) { barChart1Hidden = true  }
  if (vline1data == null) { lineChart1Hidden = true }
  if (vline2data == null) { lineChart2Hidden = true }
  if (vbardata == null && vline1data && vline2data == null) { 
    alert ('NO DATA - Check Filters'); return;}
//**************************************************************
//**************************************************************
//**************************************************************
// We have Data to plot!
//  Transform data from queries into prop arrays 
//  to drive VerticalComboChart3
//**************************************************************
//**************************************************************
//**************************************************************
// declare locals to facilitate debugging
let matchobj, vbarlastvalue, vline1lastvalue, vline2lastvalue
let comlength = commonDataX.length
  // Build the Y Axis values
  for (let i=0 ; i < comlength ; i++) {
  //  Set series elements to use totals <TBD> or average ??? (based on psi readings)
// debugger
    switch (Number.parseInt(sel_groupby_id)) { // switch to sum by period
        
    // GROUP BY HOURS (always one day's worth)
      case 0: { 
        if (vbardata != null) {
          matchobj = vbardata.filter(obj => {
            return obj.hour === commonDataX[i] })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsbar1.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsbar1.units, 'gal') : matchobj[0].groupTotals 
          barChart1Datay.push(matchobj[0].groupTotals)}
        else {barChart1Datay.push(0)}
        }
        if (vline1data != null) {
          matchobj = vline1data.filter(obj => {
            return obj.hour === commonDataX[i] })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsline1.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsline1.units, 'gal') : matchobj[0].groupTotals 
          lineChart1Datay.push(matchobj[0].groupTotals)}
        else {lineChart1Datay.push(0)}
        }
        if (vline2data != null) {
          matchobj = vline2data.filter(obj => {
            return obj.hour === commonDataX[i] })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsline2.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsline2.units, 'gal') : matchobj[0].groupTotals 
          lineChart2Datay.push(matchobj[0].groupTotals)}
        else {lineChart2Datay.push(0)}
        }
      break; }

    // GROUP BY DAYS  (Variable time period)
      case 1: {
        if (vbardata != null) {
          matchobj = vbarplotdata.filter(obj => {
            return obj.saveMonthDay === commonDataX[i] })
        if (matchobj.length > 0) { 
          matchobj[0].plotdata =  sel_dsbar1.units != 'gal' ? this.ConvertUnits(matchobj[0].plotdata, sel_dsbar1.units, 'gal') : matchobj[0].plotdata 
          vbarlastvalue = matchobj[0].plotdata
          barChart1Datay.push(matchobj[0].plotdata)}
        else {barChart1Datay.push(sel_dsbar1style.id == 0 ? 0 : vbarlastvalue)}
        }
        if (vline1data != null) {
          matchobj = vline1plotdata.filter(obj => {
            return obj.saveMonthDay === commonDataX[i] })
        if (matchobj.length > 0) { 
          matchobj[0].plotdata =  sel_dsline1.units != 'gal' ? this.ConvertUnits(matchobj[0].plotdata, sel_dsline1.units, 'gal') : matchobj[0].plotdata
          vline1lastvalue = matchobj[0].plotdata
          lineChart1Datay.push(matchobj[0].plotdata)}
        else {lineChart1Datay.push(sel_dsline1style.id == 0 ? 0 : vline1lastvalue)}
        }
        if (vline2data != null) {
          matchobj = vline2plotdata.filter(obj => {
            return obj.saveMonthDay === commonDataX[i] })
        if (matchobj.length > 0) { 
          matchobj[0].plotdata =  sel_dsline2.units != 'gal' ? this.ConvertUnits(matchobj[0].plotdata, sel_dsline2.units, 'gal') : matchobj[0].plotdata 
          vline2lastvalue = matchobj[0].plotdata
          lineChart2Datay.push(matchobj[0].plotdata)}
        else {lineChart2Datay.push(sel_dsline2style.id == 0 ? 0 : vline2lastvalue)}
        }
      break;  }

    // GROUP BY WEEKS  (Variable time period)
      case 2: {
        if (vbardata != null) {
          matchobj = vbardata.filter(obj => {
            return obj.weekNum === Number.parseInt(commonDataX[i]) })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsbar1.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsbar1.units, 'gal') : matchobj[0].groupTotals 
          barChart1Datay.push(matchobj[0].groupTotals)}
        else {barChart1Datay.push(0)}
        }
        if (vline1data != null) {
          matchobj = vline1data.filter(obj => {
            return obj.weekNum === Number.parseInt(commonDataX[i]) })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsline1.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsline1.units, 'gal') : matchobj[0].groupTotals 
          lineChart1Datay.push(matchobj[0].groupTotals)}
        else {lineChart1Datay.push(0)}
        }
        if (vline2data != null) {
          matchobj = vline2data.filter(obj => {
            return obj.weekNum === Number.parseInt(commonDataX[i]) })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsline2.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsline2.units, 'gal') : matchobj[0].groupTotals 
          lineChart2Datay.push(matchobj[0].groupTotals)}
        else {lineChart2Datay.push(0)}
        }
      break;  }


    // GROUP BY MONTHS  (Variable time period)
      case 3: {
        if (vbardata != null) {
          matchobj = vbardata.filter(obj => {
            return obj.monthNum === Number.parseInt(commonDataX[i]) })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsbar1.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsbar1.units, 'gal') : matchobj[0].groupTotals 
          barChart1Datay.push(matchobj[0].groupTotals)}
        else {barChart1Datay.push(0)}
        }
        if (vline1data != null) {
          matchobj = vline1data.filter(obj => {
            return obj.monthNum === Number.parseInt(commonDataX[i]) })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsline1.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsline1.units, 'gal') : matchobj[0].groupTotals 
          lineChart1Datay.push(matchobj[0].groupTotals)}
        else {lineChart1Datay.push(0)}
        }
        if (vline2data != null) {
          matchobj = vline2data.filter(obj => {
            return obj.monthNum === Number.parseInt(commonDataX[i]) })
        if (matchobj.length > 0) { 
          matchobj[0].groupTotals =  sel_dsline2.units != 'gal' ? this.ConvertUnits(matchobj[0].groupTotals, sel_dsline2.units, 'gal') : matchobj[0].groupTotals 
          lineChart2Datay.push(matchobj[0].groupTotals)}
        else {lineChart2Datay.push(0)}
        }
      break;  }

      } // end switch

// debugger

      } // End of for loop
  this.InitTableStructure()  
  // Populate the summary table
    let summary = []
    let dailysums = []
    let datatable = []
    let dsuid = sel_dsbar1.uid
    // vbardata Datasource
      if (vbardata != null) {
        summary =  await DatalogService.getAggregates(startdate, enddate, sel_dsbar1.uid)
        if (summary != null) {
          summary[0].startDate = startdate
          summary[0].endDate = enddate
          summary[0].text = sel_dsbar1.name
          // If no entries, then indicate with dash
          if (summary[0].entryCount == 0 ) {
            summary[0].periodSum = '-'
            summary[0].dailyMax = '-'
            summary[0].dailyMin = '-'
            summary[0].dailyAvg = '-'
          }
          // Aggregate Values
          const infobyday = await DatalogService.getDailySums0(startdate, enddate, sel_dsbar1.uid)
          if (infobyday != null) { dailysums = infobyday.map(a => a.groupTotals)
            summary[0].dailyAvg = (summary[0].periodSum / infobyday.length).toFixed(1)
            summary[0].dailyMax = Math.max( ...dailysums ).toFixed(0)
            summary[0].dailyMin = Math.min( ...dailysums ).toFixed(0)
          }
          datatable.push(summary[0])
        }

      }
    // vline1data Datasource
      if (vline1data != null) {
        summary =  await DatalogService.getAggregates(startdate, enddate, sel_dsline1.uid)
        if (summary != null) {
          summary[0].startDate = startdate
          summary[0].endDate = enddate
          summary[0].text = sel_dsline1.name
          // If no entries, then indicate with dash
          if (summary[0].entryCount == 0 ) {
            summary[0].periodSum = '-'
            summary[0].dailyMax = '-'
            summary[0].dailyMin = '-'
            summary[0].dailyAvg = '-'
          }
          // Aggregate Values
          const infobyday = await DatalogService.getDailySums0(startdate, enddate, sel_dsline1.uid)
          if (infobyday != null) { dailysums = infobyday.map(a => a.groupTotals)
            summary[0].dailyAvg = (summary[0].periodSum / infobyday.length).toFixed(1)
            summary[0].dailyMax = Math.max( ...dailysums ).toFixed(0)
            summary[0].dailyMin = Math.min( ...dailysums ).toFixed(0)
          }
          datatable.push(summary[0])
        }

      }
    // vline2 data Datasource
      if (vline2data != null) {
        summary =  await DatalogService.getAggregates(startdate, enddate, sel_dsline2.uid)
        if (summary != null) {
          summary[0].startDate = startdate
          summary[0].endDate = enddate
          summary[0].text = sel_dsline2.name
          // If no entries, then indicate with dash
          if (summary[0].entryCount == 0 ) {
            summary[0].periodSum = '-'
            summary[0].dailyMax = '-'
            summary[0].dailyMin = '-'
            summary[0].dailyAvg = '-'
          }
          // Aggregate Values
          const infobyday = await DatalogService.getDailySums0(startdate, enddate, sel_dsline2.uid)
          if (infobyday != null) { dailysums = infobyday.map(a => a.groupTotals)
            summary[0].dailyAvg = (summary[0].periodSum / infobyday.length).toFixed(1)
            summary[0].dailyMax = Math.max( ...dailysums ).toFixed(0)
            summary[0].dailyMin = Math.min( ...dailysums ).toFixed(0)
          }
          datatable.push(summary[0])
        }

      }
    this.datatable_data = datatable
    this.showdatatable = true
  // Set the report title
    this.combochart1title = this.reportname +" (" + startdate + " - " + enddate + ")"
    } // End of "At least 1 dataset is selected for chart
      this.keychart++
      this.dontshow_chart = false
      //******************************************
      //******* Copy results to Instance variables
      //******************************************
      this.ChartLabels=commonDataX
      this.barChart1Datax=commonDataX
      this.lineChart1Datax=commonDataX
      this.lineChart2Datax=commonDataX
      this.barChart1Datay=barChart1Datay
      this.lineChart1Datay=lineChart1Datay
      this.lineChart2Datay=lineChart2Datay
      this.barChart1Hidden = barChart1Hidden
      this.lineChart1Hidden = lineChart1Hidden
      this.lineChart2Hidden = lineChart2Hidden
      // this.sel_dsbar1 = sel_dsbar1
      // this.sel_dsline1 = sel_dsline1
      // this.sel_dsline2 = sel_dsline2

// debugger
    } // End - we have data to plot
      
    catch (err) {
      console.log('ComboChart1 - UpdateChart: err= ', err.message)
    }
  },
}, // End METHODS
//************************
// LIFECYCLE HOOKS
//************************
created () {
  // debugger
  this.showfilters = true
  this.populateOrganizations(); // Kicks off initialization
  this.setGroupBy()
  // this.showfilters = true   // This setting of showfilters is to force the Vue responsive rendering to detect a change when settings are loaded.  I tried a lot of things before coming up with this hack.
},

} // Export default end bracket

</script>

<!-- ************************** -->
<!-- *****  LOCAL STYLES  ***** -->
<!-- ************************** -->

<style lang="scss" scoped>
#table > .v-data-header .v-text {
  color: rgb(248, 2, 2);
    font-size: 14px ;
  }

  .v-toolbar__title {
    font-size: 22px;
  }

</style>