/* eslint-disable */
import axios from 'axios';
// import { json } from 'express';

const url = 'api/dl2/';

class Datalog2Service {


  // Get the specified number of most recent Entries of Datalog2 Data
  static getMostRecentEntries(numentries, dsuid) {
    return new Promise(async (resolve, reject) => {
      try {
        // debugger
        const res = await axios.get(url+'mostrecentn',{
          params: {
            numentries,
            dsuid}
          });
        const data1 = res.data
        // console.log('data1 (most recent entries)= ',data1)
        resolve(
          data1.data
        )  
      } catch(err) {
        reject(err);
      }
    });
  }
  
// ******* Return a specified Datalog2 record by id
static getDL2RecordById(dsid) {
  return new Promise( async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url + 'getbyid', {
        params: {
          dsid
        }
        });
        const data1 = res.data
        // console.log('data1 (dl2)', data1)
        resolve(
          data1.data
        )
      } catch(err) {
        reject(err);
      }
    });
  }

  // ******* Get All Datalog2 Entries for a specific date range & datasource
  static getDL2RecordByUid(startdate, enddate, dsuid) {
  return new Promise( async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url + 'dtrangeentries', {
        params: {
          startdate,
          enddate,
          dsuid
        }
        });
        const data1 = res.data
        // console.log('data1 (dl2)', data1)
        resolve(
          data1.data
        )
      } catch(err) {
        reject(err);
      }
    });
  }

  // ******* Create a new Datalog2 record 
  static createDL2Record(timestamp, value, dsuid, schemaid, jsondata) {
    return new Promise( async (resolve, reject) => {
      // debugger
      try {
        const res = await axios.get(url + 'createentry', {
          params: {
            timestamp,
            value,
            dsuid,
            schemaid,
            jsondata
          }
          });
          const data1 = res.data
          // console.log('data1 (dl2)', data1)
          resolve(
            data1.data
          )
        } catch(err) {
          reject(err);
        }
      });
  }

// ******* Update an existing Datalog2 record  
// *******  NOTE: it is intentional that only 
// *******  jsondata and value can be updated
static updateDL2Record(dsid, value, jsondata) {
    return new Promise( async (resolve, reject) => {
      // debugger
      try {
        const res = await axios.get(url + 'updateentry', {
          params: {
            dsid,
            value,
            jsondata
          }
          });
          const data1 = res.data
          // console.log('data1 (dl2)', data1)
          resolve(
            data1.data
          )
        } catch(err) {
          reject(err);
        }
      });
    }

  static deleteDL2Record(dsid) {
    return new Promise( async (resolve, reject) => {
      // debugger
      try {
        const res = await axios.get(url + 'deleteentry', {
          params: {
            dsid
          }
          });
          const data1 = res.data
          // console.log('data1 (dl2)', data1)
          resolve(
            data1.data
          )
        } catch(err) {
          reject(err);
        }
      });
    }
}

  export default Datalog2Service;